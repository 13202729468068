import p5 from "p5";
import assetBgImg from "../image/bg.jpg";
import Rain from "./p5/Rain";


new p5((s: p5) => {
    let bgImg: p5.Image;
    let rainLimit = 200;
    let raindrops: Rain[] = [];

    let drawBackground = () => {
        // mouse pointerの中心からのズレが+-1でもらえるので、視差っぽくする量
        let ratioX = (s.mouseX / window.innerWidth * 2 - 1) ;
        let ratioY = (s.mouseY / window.innerHeight * 2 - 1);
        let offsetX = ratioX * window.innerWidth * -0.02;
        let offsetY = ratioY * window.innerHeight * -0.01;
        let x = window.innerWidth / 2 + offsetX;
        let y = window.innerHeight / 2 + offsetY;

        // アスペクト比守る
        let zoomRatio = 1.2;
        let height = window.innerHeight * zoomRatio;
        let width = (window.innerHeight / bgImg.height) * bgImg.width * zoomRatio;

        s.background(0);
        s.imageMode(s.CENTER);
        s.image(bgImg, x,y, width, height);
    };

    let getBackgroundColor = (x: number, y: number): number[] => {
        return bgImg.get(Math.floor(x), Math.floor(y));
    };

    let applyGlitchEffect = () => {
        let startX = Math.floor(s.random(0, window.innerWidth));
        let startY = Math.floor(s.random(0, window.innerHeight));
        let endX = startX + Math.floor(s.random(window.innerWidth / 1));
        let endY = startY + Math.floor(s.random(window.innerHeight / 30));
        let xOffset = Math.floor(s.random(-window.innerWidth, window.innerWidth));
        let yOffset = Math.floor(s.random(-window.innerHeight, window.innerHeight));
        s.copy(bgImg, startX, startY, endX, endY, xOffset, yOffset, endX - startX, endY - startY);
    }

    function applyScanlineEffect() {
        let lineWidth = 2;
        let scanlineMoveYPerSec = 10;
        let scanlineIntensity = 100;

        let scanMove = (scanlineMoveYPerSec * s.millis() / 1000.0) % lineWidth;
        for (let y = 0; y < s.height; y += lineWidth) {
            s.stroke(0, scanlineIntensity);
            s.line(0,y + scanMove, s.width, y + scanMove);
        }
    }

    function applyLightStreaksEffect() {
        s.noFill();
        s.stroke(0, 100);
        for (let i = 0; i < s.random(0, 30); i++) {
            let x1 = s.random(s.width);
            let y1 = 0;
            let x2 = x1 + s.random(-20, 20);
            let y2 = s.height;
            s.line(x1, y1, x2, y2);
        }
    }

    function applyNeonEffect() {
        s.noFill();
        s.stroke(0, 0, 0);
        s.strokeWeight(1);
        s.rectMode(s.CENTER);
        for (let i = 0; i < 10; i++) {
            let x = s.random(s.width);
            let y = s.random(s.height);
            let size = s.random(5, 10);
            s.rect(x, y, size, size);
        }
    }

    s.preload = () => { 
        bgImg = s.loadImage(assetBgImg);
    }
    s.setup = () => {
        s.createCanvas(window.innerWidth, window.innerHeight);

        for (let i = 0; i < rainLimit; i++) {
            raindrops.push(new Rain(s));
        }
    };
    s.windowResized = () => {
        s.resizeCanvas(window.innerWidth, window.innerHeight);
    };
    s.draw = () => {
        drawBackground();
        for (let i = 0; i < raindrops.length; i++) {
             let bgColor = getBackgroundColor(raindrops[i].x, raindrops[i].y);
            raindrops[i].setColor(bgColor);
            raindrops[i].fall();
            raindrops[i].display();
        }
        // applyNeonEffect();
        applyLightStreaksEffect();
        applyGlitchEffect();
        applyScanlineEffect();
    };
});