import p5 from "p5";

export default class Rain {
    s: p5;
    x: number;
    y: number;
    speed: number;
    length: number;
    color: number[];

    constructor(s: p5) {
        this.s = s;
        this.x = Math.random() * window.innerWidth;
        this.y = Math.random() * window.innerHeight;
        // 中央ほど遅く
        let archBoost = Math.random() * Math.abs((this.x / window.innerWidth) - 0.5) * 30;
        this.speed = Math.random() * (15 - 5) + 5 + archBoost;
        this.length = Math.random() * (20 - 10) + 10 + archBoost;
        this.color = [0, 0, 0];
    }

    fall() {
        this.y += this.speed;
        if (this.y > window.innerHeight) {
            this.y = Math.random() * -100 - 10;
            this.x = Math.random() * window.innerWidth;
        }
    }

    setColor(color: number[]) {
        this.color = color;
    }

    display() {
        this.s.stroke(this.color[0], this.color[1], this.color[2]);
        this.s.line(this.x, this.y, this.x, this.y + this.length);
    }
}